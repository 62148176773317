import React, { useState, useEffect, useRef } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { ref, set, onValue, off } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { decode } from "he";
import "./index.css";
import {
  FileText,
  Settings2,
  Mail,
  LogOut,
  Copy,
  Check,
  Loader,
  X,
  ShieldCheck,
  Zap,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@radix-ui/react-dropdown-menu";
import { ReactComponent as MagicIcon } from "./assets/magic.svg";
import { ReactComponent as GoogleLogo } from "./assets/google-logo.svg";
import { CustomSelect } from "./customSelect";
import { languages } from "./data";
import { ContentPlaceholder, ProFeatureList } from "./components";
import {
  firebaseAuth,
  firebaseDatabase,
  firebaseFunctions,
} from "./firebaseConfig";
import { parseISO, isAfter, format } from "date-fns";
import { MessageCircle } from "lucide-react";
import Markdown from "react-markdown";

const getDefaultLanguage = () => {
  let browserLang;

  if (typeof navigator !== "undefined") {
    browserLang = (navigator.language || navigator.userLanguage || "").split(
      "-"
    )[0];
  }

  if (!browserLang) {
    browserLang = "en";
  }

  const matchedLang = languages.find(
    (lang) => lang.code.toLowerCase() === browserLang.toLowerCase()
  );

  return matchedLang ? matchedLang.value : "english";
};

const details = {
  email: "mailto:support@waspthemes.com?subject=SkipWatch Subscription",
  checkout_link: "http://skipwatch.ai/checkout",
  manage_subscription_link: "https://skipwatch.ai/manage-subscription",
  annual_plan_id: process.env.REACT_APP_PADDLE_ANNUAL_PLAN_ID,
  annualPrice: parseFloat(process.env.REACT_APP_PADDLE_ANNUAL_PRICE),
};

const defaultUserData = {
  settings: {
    language: null,
  },
  subscription: {},
};

defaultUserData.settings.language = getDefaultLanguage();

function App() {
  const [user, setUser] = useState({
    name: "",
    avatar: "/api/placeholder/24/24",
  });
  const [userData, setUserData] = useState(defaultUserData);
  const [loading, setLoading] = useState(true);
  const [videoId, setVideoId] = useState(null);
  const [subtitleURL, setSubtitleURL] = useState(null);
  const [AIResponse, setAIResponse] = useState(null);
  const [activeTab, setActiveTab] = useState("summary");
  const [showSettings, setShowSettings] = useState(false);
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);
  const [processLoading, setProcessLoading] = useState(false);
  const rootRef = useRef(null);
  const mainRef = useRef(null);
  const [topOpacity, setTopOpacity] = useState(0);
  const [bottomOpacity, setBottomOpacity] = useState(0);
  const [minMainHeight, setMinMainHeight] = useState(0);
  const [updateHeight, setUpdateHeight] = useState(0);
  const [showPlan, setShowPlan] = useState(false);
  const [error, setError] = useState("");
  const [isDarkMode, setDarkMode] = useState(false);

  const [messages, setMessages] = useState([]);
  const [chatLoading, setChatLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current && mainRef.current) {
      const mainElement = mainRef.current;
      const scrollHeight = mainElement.scrollHeight;
      mainElement.scrollTop = scrollHeight;
    }
  };

  useEffect(() => {
    setError("");
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === "chat") {
      scrollToBottom();
    }
  }, [messages, activeTab]);

  const handleSendMessage = async (content) => {
    if (!user) {
      setError("Please log in to continue.");
      return;
    }

    if (
      !canUsePro() &&
      typeof userData.settings.freeCredits === "number" &&
      userData.settings.freeCredits <= 0
    ) {
      setError("You're out of free credits. Upgrade your plan to continue.");
      setShowPlan(true);
      return;
    }

    if (!subtitleURL || !videoId) {
      setError("Missing subtitle URL or video ID. Please check and try again.");
      return;
    }

    setChatLoading(true);

    const newMessage = {
      role: "user",
      content,
    };

    const history = [...messages];

    setMessages((prev) => [...prev, newMessage]);

    const ChatRequest = httpsCallable(firebaseFunctions, "ChatRequest");

    try {
      const result = await ChatRequest({
        subtitleURL: subtitleURL,
        videoId: videoId,
        history: history,
        message: content,
      });

      if (result.data) {
        setMessages((prev) => [
          ...prev,
          {
            role: "model",
            content: result.data.response,
          },
        ]);
      }
    } catch (error) {
      console.error(error);
      setMessages((prev) => [
        ...prev,
        {
          role: "model",
          content: "Sorry, I encountered an error. Please try again.",
        },
      ]);
    } finally {
      setChatLoading(false);
    }
  };

  const canUsePro = () => {
    if (!user) {
      return false;
    }

    if (!userData?.subscription || !userData?.subscription?.nextBillDate) {
      return false;
    }

    try {
      const nextBillDate = parseISO(userData?.subscription?.nextBillDate);
      return isAfter(nextBillDate, new Date());
    } catch (error) {
      console.error("Error parsing date:", error);
      return false;
    }
  };

  const handleCheckout = () => {
    const checkoutUrl = `${details.checkout_link}?planid=${getPlanId()}`;
    window.open(checkoutUrl, "_blank");
  };

  const handleScroll = () => {
    if (mainRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = mainRef.current;
      const fadeDistance = 20;

      const hasScrollTop = scrollTop > 0;
      const newTopOpacity = hasScrollTop
        ? Math.min(scrollTop / fadeDistance, 1)
        : 0;
      setTopOpacity(newTopOpacity);

      const hasScrollBottom = scrollHeight > clientHeight + scrollTop;
      const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
      const newBottomOpacity = hasScrollBottom
        ? distanceFromBottom / fadeDistance
        : 0;

      setBottomOpacity(newBottomOpacity);
    }
  };

  useEffect(() => {
    const mainElement = mainRef.current;
    if (mainElement) {
      mainElement.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (mainElement) {
        mainElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    setAIResponse(null);
    setMessages([]);
  }, [videoId]);

  const sendMessageToParent = (message) => {
    try {
      if (window.parent) {
        window.parent.postMessage(message, "*");
      } else {
        throw new Error("Parent window not found");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    function handleMessage(event) {
      const { type, value } = event.data;
      if (type === "SKIPWATCH_SUBTITLE_URL") {
        if (typeof value === "string") {
          const finalURL = value.split(
            "https://www.youtube.com/api/timedtext"
          )[1];
          setSubtitleURL(finalURL);
        } else {
          setSubtitleURL(null);
        }
      } else if (type === "SKIPWATCH_VIDEO_ID") {
        setVideoId(value);
      } else if (type === "SKIPWATCH_DARK_MODE") {
        setDarkMode(value);
      }
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (rootRef.current) {
      const height = rootRef.current.offsetHeight;
      if (height > 0) {
        sendMessageToParent({
          type: "SKIPWATCH_IFRAME_HEIGHT",
          value: height,
        });
      }
    }
  }, [
    loading,
    activeTab,
    showSettings,
    AIResponse,
    minMainHeight,
    updateHeight,
    showPlan,
    processLoading,
    user,
    userData,
    error,
    videoId,
    chatLoading,
    messages,
  ]);

  useEffect(() => {
    if (user && user.uid) {
      const userRef = ref(firebaseDatabase, `users/${user.uid}`);

      const userListener = onValue(
        userRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setUserData((prevData) => ({
              ...prevData,
              ...data,
            }));
          } else {
            set(userRef, defaultUserData);
          }
        },
        (error) => {
          console.error("Error fetching user data:", error);
        }
      );

      return () => {
        off(userRef, "value", userListener);
      };
    } else {
      setUserData(defaultUserData);
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      setUser(user);

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleSettingChange = (key, value) => {
    if (user) {
      set(
        ref(firebaseDatabase, `users/${user.uid}/settings/${key}`),
        value
      ).catch((error) => {
        console.error(error);
      });
    }
  };

  const handleCopy = () => {
    if (AIResponse) {
      const summaryText = AIResponse.insights
        .map(
          (item) =>
            `${item.topic}\n${item.insights
              .map((insight) => {
                const unstarredInsight = insight.insight.replace(
                  /\*\*(.*?)\*\*/gi,
                  "$1"
                );
                return `${insight.emoji} ${decode(unstarredInsight)}`;
              })
              .join("\n")}`
        )
        .join("\n\n");
      navigator.clipboard.writeText(summaryText);
      setShowCopyConfirmation(true);
      setTimeout(() => setShowCopyConfirmation(false), 5000);
    }
  };

  const signIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(firebaseAuth, provider);
    } catch (error) {
      console.error(error);
    }
  };

  const getPlanId = () => {
    return details.annual_plan_id;
  };

  const handleSignOut = async () => {
    try {
      await signOut(firebaseAuth);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (processLoading === true || chatLoading === true) {
      setError("");
    }
  }, [processLoading, chatLoading, videoId]);

  useEffect(() => {
    setError("");
  }, [videoId]);

  const handleAIRequests = async () => {
    if (!user) {
      setError("Please log in to continue.");
      return;
    }

    if (
      !canUsePro() &&
      typeof userData.settings.freeCredits === "number" &&
      userData.settings.freeCredits <= 0
    ) {
      setError("You're out of free credits. Upgrade your plan to continue.");
      setShowPlan(true);
      return;
    }

    if (!subtitleURL || !videoId) {
      setError("Missing subtitle URL or video ID. Please check and try again.");
      return;
    }

    setProcessLoading(true);
    const SummarizeRequest = httpsCallable(
      firebaseFunctions,
      "SummarizeRequest"
    );

    try {
      const result = await SummarizeRequest({
        subtitleURL: subtitleURL,
        videoId: videoId,
      });

      if (result.data) {
        setAIResponse(result.data.summary);
      } else {
        throw new Error("Unexpected result structure");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setProcessLoading(false);
    }
  };

  const renderText = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/);
    return parts.map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        return (
          <span
            key={index}
            style={{ backgroundColor: "rgba(255, 109, 36, 0.22)" }}
          >
            {part.slice(2, -2)}
          </span>
        );
      }
      return part;
    });
  };

  if (loading) {
    return <></>;
  }

  return (
    <div
      className={isDarkMode ? "is-dark-mode" : null}
      ref={rootRef}
      style={{
        backgroundColor: isDarkMode ? "#151515" : "#ffffff",
        color: isDarkMode ? "#ffffff" : "#000000",
      }}
    >
      {showPlan === true ? (
        <>
          {!canUsePro() &&
          ["active", "past_due"].indexOf(userData?.subscription?.status) ===
            -1 ? (
            <div className={"p-4"}>
              <div className="flex justify-between items-center mb-4">
                <img
                  width="131"
                  height="25"
                  src={
                    isDarkMode
                      ? "https://skipwatch.ai/assets/logo-white.svg"
                      : "https://skipwatch.ai/assets/logo.svg"
                  }
                  alt="SkipWatch Logo"
                />
                <button
                  className={`p-1 rounded ${
                    isDarkMode ? "hover:bg-neutral-700" : "hover:bg-gray-200"
                  }`}
                >
                  <X
                    className={`${
                      isDarkMode ? "text-neutral-300" : "text-gray-500"
                    } cursor-pointer`}
                    onClick={() => {
                      setShowPlan(false);
                    }}
                  />
                </button>
              </div>

              <h3 className="text-2xl font-bold mb-4">Upgrade to Premium</h3>
              <ProFeatureList />

              <button
                onClick={handleCheckout}
                className={`w-full py-2 rounded-lg font-semibold transition-colors text-white ${
                  isDarkMode
                    ? "bg-orange-700 hover:bg-orange-800"
                    : "bg-orange-500 hover:bg-orange-600"
                }`}
              >
                Upgrade to Pro Now
              </button>

              <p
                className={`text-sm mt-4 flex items-center gap-2 ${
                  isDarkMode ? "text-neutral-400" : "text-gray-600"
                }`}
              >
                <ShieldCheck className="text-green-500 w-9 h-9" />
                If you're not satisfied, we'll provide a refund without
                question.
              </p>
            </div>
          ) : (
            <div className="p-4">
              <div className="flex justify-between items-center mb-4">
                <img
                  width="131"
                  height="25"
                  src={
                    isDarkMode
                      ? "https://skipwatch.ai/assets/logo-white.svg"
                      : "https://skipwatch.ai/assets/logo.svg"
                  }
                  alt="SkipWatch Logo"
                />
                <button
                  className={`p-1 rounded ${
                    isDarkMode ? "hover:bg-neutral-700" : "hover:bg-gray-200"
                  }`}
                >
                  <X
                    className={`${
                      isDarkMode ? "text-neutral-300" : "text-gray-500"
                    } cursor-pointer`}
                    onClick={() => {
                      setShowPlan(false);
                    }}
                  />
                </button>
              </div>
              <h2 className="text-2xl font-bold mb-3">Annual Plan</h2>
              {userData?.subscription?.status !== "active" && canUsePro() ? (
                <>
                  <p
                    className={`mb-4 ${
                      isDarkMode ? "text-neutral-400" : "text-gray-600"
                    }`}
                  >
                    Will expire on{" "}
                    {format(
                      new Date(userData?.subscription?.nextBillDate),
                      "MMM d, yyyy"
                    )}
                  </p>
                </>
              ) : userData?.subscription?.status === "past_due" ? (
                <>
                  <p className="text-red-600 mb-4 font-medium">
                    Last payment attempt failed. Please{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={userData?.subscription?.updateUrl}
                      className="underline cursor-pointer"
                    >
                      update your payment method
                    </a>
                    .
                  </p>
                </>
              ) : null}
              <ProFeatureList />
              <div
                className={`text-sm ${
                  isDarkMode ? "text-neutral-400" : "text-gray-500"
                }`}
              >
                <a href={details.email} className="hover:underline">
                  Contact support
                </a>{" "}
                •{" "}
                <a
                  href={details.manage_subscription_link}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline"
                >
                  Manage subscription
                </a>
              </div>
            </div>
          )}
        </>
      ) : user ? (
        <div className="flex flex-col overflow-y-auto overflow-x-hidden">
          <header className="select-none">
            <div className="mx-auto px-4 py-3">
              <div className="flex justify-between items-center">
                <div className="flex space-x-2">
                  <button
                    className={`cursor-default flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                      activeTab === "summary"
                        ? isDarkMode
                          ? "bg-neutral-700"
                          : "bg-gray-200 text-gray-900"
                        : isDarkMode
                        ? "text-neutral-400 hover:text-neutral-300"
                        : "text-gray-500 hover:text-gray-700"
                    }`}
                    onClick={() => {
                      setActiveTab("summary");
                    }}
                  >
                    <FileText className="mr-2 h-4 w-4" />
                    Summary
                  </button>
                  <button
                    className={`cursor-default flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                      activeTab === "chat"
                        ? isDarkMode
                          ? "bg-neutral-700"
                          : "bg-gray-200 text-gray-900"
                        : isDarkMode
                        ? "text-neutral-400 hover:text-neutral-300"
                        : "text-gray-500 hover:text-gray-700"
                    }`}
                    onClick={() => {
                      setActiveTab("chat");
                    }}
                  >
                    <MessageCircle className="mr-2 h-4 w-4" />
                    Chat
                  </button>
                </div>

                <div className="flex items-center space-x-2">
                  <div
                    className={`flex ${
                      isDarkMode ? "text-neutral-400" : "text-gray-600"
                    } space-x-1 transition-colors duration-300`}
                  >
                    <button
                      disabled={AIResponse === null}
                      onClick={handleCopy}
                      className={`p-1.5 rounded ${
                        AIResponse === null
                          ? "opacity-30"
                          : showCopyConfirmation
                          ? isDarkMode
                            ? "bg-green-900 hover:bg-green-800"
                            : "bg-green-100 hover:bg-green-200"
                          : isDarkMode
                          ? "hover:bg-neutral-700"
                          : "hover:bg-gray-200"
                      }`}
                    >
                      {showCopyConfirmation ? (
                        <Check
                          className={`h-4 w-4 ${
                            isDarkMode ? "text-green-400" : "text-green-600"
                          }`}
                        />
                      ) : (
                        <Copy className="h-4 w-4" />
                      )}
                    </button>
                    <button
                      onClick={() => setShowSettings(!showSettings)}
                      className={`p-1.5 rounded ${
                        showSettings
                          ? isDarkMode
                            ? "bg-neutral-700"
                            : "bg-gray-200"
                          : isDarkMode
                          ? "hover:bg-neutral-700"
                          : "hover:bg-gray-200"
                      }`}
                    >
                      {showSettings ? (
                        <X className="h-4 w-4" />
                      ) : (
                        <Settings2 className="h-4 w-4" />
                      )}
                    </button>
                  </div>

                  <DropdownMenu
                    onOpenChange={(events) => {
                      if (events) {
                        setMinMainHeight(120);
                      } else {
                        setMinMainHeight(0);
                      }
                    }}
                  >
                    <DropdownMenuTrigger>
                      <img
                        src={user.photoURL}
                        alt={user.name}
                        className="w-6 h-6 rounded-full cursor-pointer"
                      />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      className={`min-w-[180px] ${
                        isDarkMode
                          ? "bg-neutral-800 text-white border-neutral-700"
                          : "bg-white border-gray-300"
                      } rounded-md border shadow-lg py-1 mt-1 z-50`}
                      sideOffset={4}
                      align="end"
                    >
                      <DropdownMenuItem
                        className={`px-3 py-1 flex items-center outline-none cursor-pointer ${
                          isDarkMode
                            ? "hover:bg-neutral-700"
                            : "hover:bg-gray-100"
                        }`}
                        onClick={() => {
                          setShowPlan(true);
                          setMinMainHeight(0);
                        }}
                      >
                        <Zap className="mr-2 h-4 w-4" />
                        <span>
                          {userData?.subscription?.status === undefined
                            ? "Upgrade"
                            : "My Plan"}
                        </span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          window.location.href = details.email;
                        }}
                        className={`px-3 py-1 flex items-center outline-none cursor-pointer ${
                          isDarkMode
                            ? "hover:bg-neutral-700"
                            : "hover:bg-gray-100"
                        }`}
                      >
                        <Mail className="mr-2 h-4 w-4" />
                        <span>Support</span>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator
                        className={`my-1 border-t ${
                          isDarkMode ? "border-neutral-700" : "border-gray-200"
                        }`}
                      />
                      <DropdownMenuItem
                        onClick={handleSignOut}
                        className={`px-3 py-1 flex items-center outline-none cursor-pointer ${
                          isDarkMode
                            ? "text-red-400 hover:bg-neutral-700"
                            : "text-red-600 hover:bg-red-100"
                        }`}
                      >
                        <LogOut className="mr-2 h-4 w-4" />
                        <span>Sign out</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            </div>
          </header>

          <div className="relative flex-1">
            {!showSettings && (
              <div
                className="content-gradient-top"
                style={{ opacity: topOpacity }}
              />
            )}

            <main
              ref={mainRef}
              className="flex-1 px-4 overflow-auto pb-4"
              style={{
                maxHeight: "500px",
                minHeight: minMainHeight,
              }}
            >
              {showSettings ? (
                <div>
                  <h3
                    className={`text-lg font-bold mb-2 ${
                      isDarkMode ? "text-white" : "text-black"
                    }`}
                  >
                    Settings
                  </h3>
                  <div>
                    <label
                      htmlFor="language-select"
                      className={`block text-sm font-medium mb-1 ${
                        isDarkMode ? "text-neutral-300" : "text-gray-700"
                      }`}
                    >
                      Language
                    </label>
                    <CustomSelect
                      isDarkMode={isDarkMode}
                      options={languages}
                      value={userData.settings.language}
                      onToggle={() => {
                        setUpdateHeight(Math.random());
                      }}
                      onChange={(value) => {
                        handleSettingChange("language", value);
                        setAIResponse(null);
                        setMessages([]);
                        handleAIRequests();
                      }}
                    />
                  </div>
                </div>
              ) : activeTab === "summary" ? (
                <>
                  {processLoading && (
                    <ContentPlaceholder
                      style={{ transform: "translateY(0.75rem)" }}
                    />
                  )}

                  {error !== "" && !loading ? (
                    <div
                      className={`px-3 py-2 rounded-md text-sm ${
                        isDarkMode
                          ? "border border-red-900 text-red-400"
                          : "bg-orange-50 text-orange-700"
                      }`}
                      role="alert"
                    >
                      <p>{error}</p>
                    </div>
                  ) : null}
                  {AIResponse
                    ? AIResponse.insights.map((item, index) => (
                        <div key={index}>
                          <h3 className="text-lg font-bold mt-2 mb-2">
                            {item.topic}
                          </h3>
                          <div>
                            {item.insights.map((insight, insightIndex) => (
                              <div
                                key={insightIndex}
                                className="mt-2 mb-2 flex gap-2"
                              >
                                <span>{insight.emoji}</span>
                                <p>{renderText(decode(insight.insight))}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))
                    : null}
                </>
              ) : activeTab === "chat" ? (
                <>
                  <div className="flex flex-col gap-y-3 pt-4 pb-4">
                    {messages.map((message, index) => (
                      <div
                        key={index}
                        className={`flex ${
                          message.role === "user"
                            ? "justify-end"
                            : "justify-start"
                        }`}
                      >
                        <div
                          className={`max-w-[80%] rounded-lg px-4 py-2 ${
                            message.role === "user"
                              ? isDarkMode
                                ? "bg-orange-700 text-white"
                                : "bg-orange-500 text-white"
                              : isDarkMode
                              ? "bg-neutral-700 text-white"
                              : "bg-gray-200 text-gray-900"
                          }`}
                        >
                          <p className="chat-content">
                            <Markdown>{message.content}</Markdown>
                          </p>
                        </div>
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </div>
                  {error !== "" && (
                    <div
                      className={`px-3 py-2 rounded-md text-sm ${
                        isDarkMode
                          ? "border border-red-900 text-red-400"
                          : "bg-orange-50 text-orange-700"
                      }`}
                      role="alert"
                    >
                      <p>{error}</p>
                    </div>
                  )}
                </>
              ) : null}
            </main>
            {!showSettings && (
              <div
                className="content-gradient-bottom"
                style={{ opacity: bottomOpacity }}
              />
            )}
          </div>

          {!showSettings && (
            <>
              {activeTab === "summary" && AIResponse === null ? (
                <footer className="flex-1 px-4">
                  <button
                    onClick={handleAIRequests}
                    disabled={processLoading}
                    className={`
        flex items-center justify-center px-4 py-2 
        w-full rounded-md text-white font-bold
        transition duration-150 ease-in-out 
        mb-3
        bg-gradient-to-r 
        ${
          processLoading
            ? isDarkMode
              ? "bg-neutral-700 text-gray-200"
              : "bg-gray-400"
            : isDarkMode
            ? "from-orange-600 to-orange-700 hover:from-orange-500 hover:to-orange-600"
            : "from-orange-500 to-orange-600 hover:from-orange-400 hover:to-orange-500"
        }
        focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50
      `}
                  >
                    {processLoading ? (
                      <>
                        <Loader className="animate-spin mr-2 w-4 h-4" />
                        Summarizing Video...
                      </>
                    ) : (
                      <>
                        <MagicIcon className="mr-2" /> Summarize Video
                      </>
                    )}
                  </button>
                </footer>
              ) : activeTab === "chat" ? (
                <footer className="flex-1 px-4 relative z-50">
                  <div className="mb-3">
                    {chatLoading && (
                      <div className={`flex justify-start mb-2`}>
                        <div
                          className={`max-w-[80%] rounded-lg px-4 py-2 ${
                            isDarkMode ? "bg-neutral-700" : "bg-gray-200"
                          }`}
                        >
                          <div className="flex space-x-2">
                            <div className="w-2 h-2 rounded-full bg-gray-400 animate-bounce" />
                            <div
                              className="w-2 h-2 rounded-full bg-gray-400 animate-bounce"
                              style={{ animationDelay: "0.2s" }}
                            />
                            <div
                              className="w-2 h-2 rounded-full bg-gray-400 animate-bounce"
                              style={{ animationDelay: "0.4s" }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        const input = e.target.elements.message;
                        if (input.value.trim()) {
                          handleSendMessage(input.value);
                          input.value = "";
                        }
                      }}
                    >
                      <div className="flex gap-2">
                        <input
                          type="text"
                          name="message"
                          disabled={chatLoading || !videoId}
                          placeholder="Type your message..."
                          className={`flex-1 px-4 py-2 rounded-lg ${
                            isDarkMode
                              ? "bg-neutral-700 text-white border-neutral-600"
                              : "bg-white text-gray-900 border-gray-300"
                          } border focus:outline-none focus:ring-2 focus:ring-orange-500`}
                        />
                        <button
                          type="submit"
                          disabled={chatLoading || !videoId}
                          className={`px-4 py-2 rounded-lg font-medium transition-colors ${
                            chatLoading || !videoId
                              ? isDarkMode
                                ? "bg-neutral-700 text-neutral-400"
                                : "bg-gray-200 text-gray-400"
                              : isDarkMode
                              ? "bg-orange-700 text-white hover:bg-orange-600"
                              : "bg-orange-500 text-white hover:bg-orange-400"
                          }`}
                        >
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                </footer>
              ) : null}
            </>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-between p-4">
          <img
            width="131"
            height="25"
            src={
              isDarkMode
                ? "https://skipwatch.ai/assets/logo-white.svg"
                : "https://skipwatch.ai/assets/logo.svg"
            }
            alt="SkipWatch Logo"
          />
          <button
            onClick={signIn}
            className={`flex items-center justify-center gap-2 px-5 py-2 rounded-full border ${
              isDarkMode
                ? "bg-black text-white border-neutral-700 hover:bg-neutral-800"
                : "bg-white text-black border-gray-300 hover:bg-gray-100"
            }`}
          >
            <GoogleLogo />
            <span className="text-sm font-medium">Sign in with Google</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
