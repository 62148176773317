export const languages = [
  { value: "english", label: "English", code: "en" },
  { value: "somali", label: "Af-Soomaali", code: "so" },
  { value: "afrikaans", label: "Afrikaans", code: "af" },
  { value: "azerbaijani", label: "Azərbaycan dili", code: "az" },
  { value: "indonesian", label: "Bahasa Indonesia", code: "id" },
  { value: "malay", label: "Bahasa Melayu", code: "ms" },
  { value: "javanese", label: "Basa Jawa", code: "jv" },
  { value: "bosnian", label: "Bosanski", code: "bs" },
  { value: "breton", label: "Brezhoneg", code: "br" },
  { value: "catalan", label: "Català", code: "ca" },
  { value: "corsican", label: "Corsu", code: "co" },
  { value: "welsh", label: "Cymraeg", code: "cy" },
  { value: "danish", label: "Dansk", code: "da" },
  { value: "german", label: "Deutsch", code: "de" },
  { value: "estonian", label: "Eesti", code: "et" },
  { value: "spanish", label: "Español", code: "es" },
  { value: "basque", label: "Euskara", code: "eu" },
  { value: "french", label: "Français", code: "fr" },
  { value: "frisian", label: "Frysk", code: "fy" },
  { value: "fula", label: "Fula", code: "ff" },
  { value: "faroese", label: "Føroyskt", code: "fo" },
  { value: "irish", label: "Gaeilge", code: "ga" },
  { value: "galician", label: "Galego", code: "gl" },
  { value: "guarani", label: "Guarani", code: "gn" },
  { value: "hausa", label: "Hausa", code: "ha" },
  { value: "croatian", label: "Hrvatski", code: "hr" },
  { value: "kinyarwanda", label: "Ikinyarwanda", code: "rw" },
  { value: "inuktitut", label: "Inuktitut", code: "iu" },
  { value: "italian", label: "Italiano", code: "it" },
  { value: "swahili", label: "Kiswahili", code: "sw" },
  { value: "haitian_creole", label: "Kreyòl Ayisyen", code: "ht" },
  { value: "kurdish", label: "Kurdî (Kurmancî)", code: "ku" },
  { value: "latvian", label: "Latviešu", code: "lv" },
  { value: "lithuanian", label: "Lietuvių", code: "lt" },
  { value: "hungarian", label: "Magyar", code: "hu" },
  { value: "malagasy", label: "Malagasy", code: "mg" },
  { value: "maltese", label: "Malti", code: "mt" },
  { value: "dutch", label: "Nederlands", code: "nl" },
  { value: "norwegian", label: "Norsk", code: "no" },
  { value: "uzbek", label: "O'zbek", code: "uz" },
  { value: "polish", label: "Polski", code: "pl" },
  {
    value: "portuguese",
    label: "Português",
    code: "pt",
  },
  { value: "romanian", label: "Română", code: "ro" },
  { value: "shona", label: "Shona", code: "sn" },
  { value: "albanian", label: "Shqip", code: "sq" },
  { value: "slovak", label: "Slovenčina", code: "sk" },
  { value: "slovenian", label: "Slovenščina", code: "sl" },
  { value: "finnish", label: "Suomi", code: "fi" },
  { value: "swedish", label: "Svenska", code: "sv" },
  { value: "vietnamese", label: "Tiếng Việt", code: "vi" },
  { value: "turkish", label: "Türkçe", code: "tr" },
  { value: "icelandic", label: "Íslenska", code: "is" },
  { value: "czech", label: "Čeština", code: "cs" },
  { value: "greek", label: "Ελληνικά", code: "el" },
  { value: "belarusian", label: "Беларуская", code: "be" },
  { value: "bulgarian", label: "Български", code: "bg" },
  { value: "macedonian", label: "Македонски", code: "mk" },
  { value: "mongolian", label: "Монгол", code: "mn" },
  { value: "russian", label: "Русский", code: "ru" },
  { value: "serbian", label: "Српски", code: "sr" },
  { value: "tatar", label: "Татарча", code: "tt" },
  { value: "tajik", label: "Тоҷикӣ", code: "tg" },
  { value: "ukrainian", label: "Українська", code: "uk" },
  { value: "kyrgyz", label: "кыргызча", code: "ky" },
  { value: "kazakh", label: "Қазақша", code: "kk" },
  { value: "armenian", label: "Հայերեն", code: "hy" },
  { value: "hebrew", label: "עברית", code: "he" },
  { value: "urdu", label: "اردو", code: "ur" },
  { value: "arabic", label: "العربية", code: "ar" },
  { value: "persian", label: "فارسی", code: "fa" },
  { value: "pashto", label: "پښتو", code: "ps" },
  { value: "nepali", label: "नेपाली", code: "ne" },
  { value: "marathi", label: "मराठी", code: "mr" },
  { value: "hindi", label: "हिन्दी", code: "hi" },
  { value: "assamese", label: "অসমীয়া", code: "as" },
  { value: "bengali", label: "বাংলা", code: "bn" },
  { value: "punjabi", label: "ਪੰਜਾਬੀ", code: "pa" },
  { value: "gujarati", label: "ગુજરાતી", code: "gu" },
  { value: "tamil", label: "தமிழ்", code: "ta" },
  { value: "telugu", label: "తెలుగు", code: "te" },
  { value: "kannada", label: "ಕನ್ನಡ", code: "kn" },
  { value: "malayalam", label: "മലയാളം", code: "ml" },
  { value: "thai", label: "ภาษาไทย", code: "th" },
  { value: "lao", label: "ພາສາລາວ", code: "lo" },
  { value: "burmese", label: "မြန်မာဘာသာ", code: "my" },
  { value: "georgian", label: "ქართული", code: "ka" },
  { value: "amharic", label: "አማርኛ", code: "am" },
  { value: "khmer", label: "ភាសាខ្មែរ", code: "km" },
  { value: "chinese", label: "中文(台灣)", code: "zh" },
  { value: "japanese", label: "日本語", code: "ja" },
  { value: "korean", label: "한국어", code: "ko" },
];
